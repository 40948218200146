import React from 'react';
import { Link } from 'gatsby';
import Layout from './../components/layout';
import { Face, Meta, Text } from '../components';

const NotFoundPage = () => {

	return (
		<Layout>
			<Meta title="Page not found"/>
			<div className="flex flex-col items-center justify-center min-h-screen bg-primary">
				<Text type="heading2" className="text-white-100">Oops, looks like this page is gone!</Text>
				<div className="my-48 w-200">
					<Face />
				</div>
				<Link to="/" className="underline font-playFair text-16">Go to start page</Link>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
